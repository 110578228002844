import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import {
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  lighten,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import Link from "../components/Link";

const useStyles = makeStyles(({ palette, typography, spacing }) =>
  createStyles({
    root: {
      width: "100%",
    },
    header: {
      backgroundColor: lighten(palette.primary.main, 0.95),

      "&.Mui-expanded": {
        borderBottom: "1px solid #dddddd",
      },
    },
    section: {
      paddingTop: 15,

      "&:first-child": {
        paddingTop: 0,
      },
    },
    question: {
      fontSize: typography.pxToRem(15),
      fontWeight: typography.fontWeightRegular,
      padding: spacing(0.5),
    },
    content: {
      marginTop: spacing(1),

      "& p:first-of-type": {
        marginTop: 0,
      },
      "& p:last-of-type": {
        marginBottom: 0,
      },
      "& ul, & ol": {
        margin: 0,
        paddingLeft: spacing(3),
      },
    },
    answer: {},
  })
);

interface QuestionAndAnswerSection {
  title: string;
  qas: Array<QuestionAndAnswer>;
}

interface QuestionAndAnswer {
  question: string;
  answer: string | ReactNode;
  googleAnswer: string;
}

const getQas: (t: any) => Array<QuestionAndAnswerSection> = (t) => [
  {
    title: t("faq.membership_section.title"),
    qas: [
      {
        question: t("faq.membership_section.sign_up.question"),
        answer: (
          <ul>
            <li>
              {t("faq.membership_section.sign_up.answer.line1_pre_link")}
              <a href={process.env.REACT_APP_SIGNUP_URL}>
                {t("faq.membership_section.sign_up.answer.line1_link")}
              </a>
              .
            </li>
            <li>{t("faq.membership_section.sign_up.answer.line2")}</li>
          </ul>
        ),
        googleAnswer: `
          <ul>
            <li>Med vår <a href={process.env.REACT_APP_SIGNUP_URL}>online innmelding</a>.</li>
            <li>I resepsjonen, mandag og torsdag, mellom kl 16:00 og 18:00.</li>
          </ul>
        `,
      },
      {
        question: t("faq.membership_section.access_card.question"),
        answer: (
          <ol>
            <li>{t("faq.membership_section.access_card.answer.line1")}</li>
            <li>{t("faq.membership_section.access_card.answer.line2")}</li>
            <li>{t("faq.membership_section.access_card.answer.line3")}</li>
            <li>
              {t("faq.membership_section.access_card.answer.line4_pre_link")}
              <a href={process.env.REACT_APP_MYPAGE_URL}>
                {t("faq.membership_section.access_card.answer.line4_link")}
              </a>
              {t("faq.membership_section.access_card.answer.line4_post_link")}
            </li>
            <li>{t("faq.membership_section.access_card.answer.line5")}</li>
          </ol>
        ),
        googleAnswer: `
          <ol>
            <li>Benytt kode mottatt på SMS for å åpne adgangsdøren.</li>
            <li>Ta et adgangskort fra resepsjonsdisken.</li>
            <li>Finn nummeret på kortets bakside.</li>
            <li>
              Logg inn på{" "}
              <a href={process.env.REACT_APP_MYPAGE_URL}>Min Side</a>, gå til
              "Adgang" og trykk på knappen "Aktiver Kort". Tast inn nummeret
              på kortet, trykk "Aktiver" og deretter "Ja".
            </li>
            <li>Test kortet ved å låse opp inngangsdøren.</li>
          </ol>
        `,
      },
      {
        question: "Hvordan logger jeg inn på Min Side?",
        answer: (
          <>
            <p>
              Gå til <a href={process.env.REACT_APP_MYPAGE_URL}>Min Side</a>.{" "}
              <br />
              <br />
              Første gang du logger inn? Trykk på "Opprett passord / Glemt
              passord", og følg retningslinjene. Når passordet er satt kan du
              logge inn.
            </p>
            <p>
              <Link to="/generell-info#minside">Les mer om Min Side</Link>
            </p>
          </>
        ),
        googleAnswer: `
          <p>
            Gå til {" "}
            <a href="${process.env.REACT_APP_MYPAGE_URL}">
              Min Side
            </a>. <br/><br/>
            Første gang du logger inn? Trykk på "Opprett passord / Glemt passord", og følg retningslinjene.
            Når passordet er satt kan du logge inn.
          </p>
          <p><a href="https://topnotchfitness.no/generell-info#minside">Les mer om Min Side</a></p>`,
      },
      {
        question: "Hvordan sier jeg opp medlemskapet mitt?",
        answer: (
          <>
            <p>
              Du sier opp medlemskapet på{" "}
              <a href={process.env.REACT_APP_MYPAGE_URL}>Min Side</a>.
            </p>
            <p>
              <Link to="/generell-info#oppsigelse">Les mer om oppsigelse</Link>
            </p>
          </>
        ),
        googleAnswer: `
          <p>
            Du sier opp medlemskapet på{" "}
            <a href="${process.env.REACT_APP_MYPAGE_URL}">
              Min Side
            </a>
            .
          </p>
          <p><a href="https://topnotchfitness.no/generell-info#oppsigelse">Les mer om oppsigelse</a></p>`,
      },
      {
        question: "Hvordan fryser jeg medlemskapet mitt?",
        answer: (
          <>
            <p>
              Du fryser medlemskapet ditt på{" "}
              <a href={process.env.REACT_APP_MYPAGE_URL}>Min Side</a>.
            </p>
            <p>
              <Link to="/generell-info#frysing">Les mer om frysing</Link>
            </p>
          </>
        ),
        googleAnswer: `
          <p>
            Du fryser medlemskapet ditt på {" "}
            <a href="${process.env.REACT_APP_MYPAGE_URL}">
              Min Side
            </a>
            .
          </p>
          <p>
          <a href="https://topnotchfitness.no/generell-info#frysing">Les mer om frysing</a>
          </p>`,
      },
      {
        question: "Hva er aldersgrensen deres?",
        answer: (
          <>
            <p>
              Aldersgrensen for medlemskap er fylte 16 år. Ungdommer under 18 år
              må melde seg inn sammen med en foresatt i resepsjonen. Både
              medlemmet og foresatt må fremvise gyldig legitimasjon ved
              innmelding.
            </p>
            <p>
              Barn og ungdom under 16 år kan ikke oppholde seg i lokalene våre.
            </p>
          </>
        ),
        googleAnswer: `<p>Aldersgrensen for medlemskap er fylte 15 år. Ungdommer under 18 år
        må melde seg inn sammen med en foresatt i resepsjonen.
        Både medlemmet og foresatt må fremvise gyldig legitimasjon ved innmelding.</p>
        `,
      },
      {
        question: "Hva koster det for medlemskap?",
        answer: (
          <>
            <p>Vårt Løpende medlemskap koster 369,- per måned.</p>
            <p>
              Se våre <Link to="/#medlemskap">medlemskap og priser</Link> for
              mer informasjon.
            </p>
          </>
        ),
        googleAnswer: `
            <p>Vårt Løpende medlemskap koster 369,- per måned.</p>
            <p>Se våre <a href="https://topnotchfitness.no/#medlemskap">medlemskap og priser</a>.</p>`,
      },
    ],
  },
  {
    title: "Åpningstider og prøvetimer",
    qas: [
      {
        question: "Hva er åpningstidene deres?",
        answer: (
          <>
            <p>
              Top Notch Fitness er åpent for medlemmer 365 dager i året, mellom
              kl 05:00 og 23:00.
            </p>
          </>
        ),
        googleAnswer: `<p>Top Notch Fitness er åpent for medlemmer 365 dager i året,
        mellom kl 05:00 og 23:00.</p>
        `,
      },
      {
        question: "Når har dere resepsjonstid?",
        answer: (
          <>
            <p>Vi har resepsjonstid mandag og torsdag, kl 16:00 til 18:00.</p>
          </>
        ),
        googleAnswer: `<p>Vi har resepsjonstid mandag og torsdag, kl 16:00 til 18:00.</p>`,
      },
      {
        question: "Tilbyr dere prøvetime?",
        answer: (
          <>
            <p>Ja, det gjør vi!</p>
            <p>
              <Link to="/bestill-provetime">Bestill prøvetime</Link>
            </p>
            <p>
              <Link to="/generell-info#provetime">Les mer om prøvetimer</Link>
            </p>
          </>
        ),
        googleAnswer: `<p>Ja, det gjør vi!</p>
            <p>
              <a href="https://topnotchfitness.no/bestill-provetime">Bestill prøvetime</Link>
            </p>
            <p><a href="https://topnotchfitness.no/generell-info#provetime">Les mer om prøvetimer</a></p>`,
      },
      {
        question: "Hva koster det for drop-in time?",
        answer: (
          <>
            <p>En enkelt drop-in time koster 100,-.</p>
            <p>
              <Link to="/bestill-provetime">Bestill drop-in</Link>
            </p>
            <p>
              Se våre <Link to="/#medlemskap">medlemskap</Link> for mer
              informasjon.
            </p>
          </>
        ),
        googleAnswer: `
            <p>En enkelt drop-in time koster 100,-.</p>
            <p>
              <a href="https://topnotchfitness.no/bestill-provetime">Bestill drop-in</a>
            </p>
            <p>Se våre <a href="https://topnotchfitness.no/generell-info#priser">priser</a>.</p>`,
      },
    ],
  },
  {
    title: "Tilbud",
    qas: [
      {
        question: "Hva slags treningsutstyr har dere?",
        answer: (
          <>
            <p>
              Vi har en solid cardioavdeling, god og variert apparatpark, egen
              core og stretching avdeling, en knallgod avdeling for funksjonell
              styrke og en frivektsavdeling fra øverste hylle.
            </p>
            <p>
              <Link to="/lokalet#utstyr">Les mer om vårt utstyr</Link>
            </p>
          </>
        ),
        googleAnswer: `
            <p>Vi har en solid cardioavdeling, god og variert apparatpark, egen core
              og stretching avdeling, en knallgod avdeling for funksjonell styrke
              og en frivektsavdeling fra øverste hylle.</p>
            <p><a href="https://topnotchfitness.no/lokalet#utstyr">Les mer om vårt utstyr</a></p>`,
      },
      {
        question: "Har dere parkering?",
        answer: (
          <>
            <p>Ja, vi har gratis parkering for bil og sykkel.</p>
            <p>
              <Link to="/lokalet#parkering">Les mer om parkering</Link>
            </p>
          </>
        ),
        googleAnswer: `
            <p>Ja, vi har gratis parkering for bil og sykkel.</p>
            <p>
              <a href="https://topnotchfitness.no/lokalet#parkering">Les mer om parkering</a>
            </p>`,
      },
      {
        question: "Har dere garderober med dusjer?",
        answer: (
          <>
            <p>Ja, vi har garderober med single dusjer.</p>
            <p>
              <Link to="/lokalet#garderober">Les mer om våre garderober</Link>
            </p>
          </>
        ),
        googleAnswer: `
            <p>Ja, vi har garderober med single dusjer.</p>
            <p>
              <a href="https://topnotchfitness.no/lokalet#garderober">Les mer om våre garderober</a>
            </p>`,
      },
      {
        question: "Tilbyr dere CrossFit?",
        answer: (
          <>
            <p>
              Vi er ikke et CrossFit-senter og tilbyr ikke Crossfit-timer, men
              vi har en sone for funksjonell trening.
            </p>
            <p>
              <Link to="/lokalet#utstyr">Les mer om vårt utstyr</Link>
            </p>
          </>
        ),
        googleAnswer: `
            <p>
              Vi er ikke et CrossFit-senter og tilbyr ikke Crossfit-timer, men
              vi har en sone for funksjonell trening.
            </p>
            <p><a href="https://topnotchfitness.no/lokalet#utstyr">Les mer om vårt utstyr</a></p>`,
      },
      {
        question: "Tilbyr dere gruppetimer?",
        answer: (
          <>
            <p>
              Nei. Vi tilbyr utstyr og fasiliteter hovedsakelig for egentrening.
            </p>
          </>
        ),
        googleAnswer: `
            <p>Nei. Vi tilbyr utstyr og fasiliteter hovedsakelig for egentrening.</p>`,
      },
    ],
  },
];

const qaToLd = (qa: QuestionAndAnswer) => ({
  "@type": "Question",
  name: qa.question,
  acceptedAnswer: {
    "@type": "Answer",
    text: qa.googleAnswer,
  },
});

const getLdJson = (t: any) => ({
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: getQas(t)
    .flatMap((qas) => qas.qas)
    .map(qaToLd),
});

const Faq = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [activeQa, setActiveQa] = useState<[number, number] | null>(null);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Få svar på de mest vanlige spørsmålene vi får fra våre kunder."
        />

        <script type="application/ld+json">
          {JSON.stringify(getLdJson(t))}
        </script>
      </Helmet>

      <div>
        <h1>OFTE STILTE SPØRSMÅL</h1>

        <div className={classes.root}>
          {getQas(t).map((section, i) => (
            <div className={classes.section}>
              <h3>{section.title}</h3>

              {section.qas.map((qa, j) => (
                <ExpansionPanel
                  expanded={
                    activeQa !== null && i === activeQa[0] && j === activeQa[1]
                  }
                >
                  <ExpansionPanelSummary
                    className={classes.header}
                    expandIcon={<ExpandMore />}
                    onClick={() => {
                      setActiveQa(
                        activeQa !== null &&
                          i === activeQa[0] &&
                          j === activeQa[1]
                          ? null
                          : [i, j]
                      );
                    }}
                    id={`panel-${j}-header`}
                    aria-controls={`panel-${j}-content`}
                  >
                    <Typography className={classes.question}>
                      {qa.question}
                    </Typography>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails className={classes.content}>
                    <Typography className={classes.answer}>
                      {qa.answer}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Faq;
