import React from "react";
import { Helmet } from "react-helmet";
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import Link from "components/Link";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      "& table": {
        maxWidth: 400,
      },
      "& tr > th:last-of-type": {
        textAlign: "right",
      },
      "& tr > td:last-of-type": {
        textAlign: "right",
      },
      "& table td": {
        fontSize: "1.05rem",
      },
    },
  })
);

const GeneralInfo = () => {
  const { protocol, host } = window.location;

  const signupUrl = `${process.env.REACT_APP_SIGNUP_URL}?returnUrl=${
    protocol + "//" + host
  }`;

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Informasjon om våre åpningstider, medlemskap, priser, og om hvordan du kan melde deg inn."
        />
      </Helmet>

      <div className={useStyles().root}>
        <Typography variant="h1">GENERELL INFO</Typography>

        <Typography id="apningstider" variant="h3" component="h2">
          Åpningstider
        </Typography>
        <Typography variant="body1">
          365 dager i året, fra 05:00 til 23:00.
        </Typography>

        <Typography id="resepsjonstider" variant="h3" component="h2">
          Resepsjonstider
        </Typography>
        <Typography variant="body1" paragraph>
          Mandag og torsdag, fra 16:00 til 18:00.
        </Typography>

        <Typography id="innmelding" variant="h3" component="h2">
          Innmelding
        </Typography>
        <Typography variant="body1" paragraph>
          Du kan melde deg inn som medlem hos Top Notch Fitness ved å benytte
          vår <a href={signupUrl}>online innmelding</a> eller i resepsjonen på
          senteret i resepsjonstiden. Gyldig legitimasjon må fremvises ved
          innmelding og ved henting av adgangskort.
        </Typography>
        <Typography id="aldersgrense" variant="h3" component="h2">
          Aldersgrense
        </Typography>
        <Typography variant="body1" paragraph>
          Aldersgrensen for medlemskap er fylte 16 år. Ungdommer under 18 år må
          melde seg inn i resepsjonen på senteret sammen med en foresatt i
          resepsjonstiden. Både medlem og foresatt må fremvise gyldig
          legitimasjon ved innmelding.
        </Typography>
        <Typography variant="body1" paragraph>
          <b>Obs!</b> Barn og ungdom under 16 år kan ikke oppholde seg i
          lokalene våre.
        </Typography>

        <Typography variant="h3" component="h2">
          Adgang
        </Typography>
        <Typography variant="body1" paragraph>
          Medlemmer kan åpne inngangsdøren til treningssenteret ved hjelp av
          adgangskode eller adgangskort. Midlertidig adgangskode blir automatisk
          tilsendt på SMS ved innmelding. Adgangskort aktiveres selvbetjent i
          resepsjonen ved første besøk.
        </Typography>
        <Typography variant="body1" paragraph>
          Dersom du har behov for nytt adgangskort kan du bestille det på{" "}
          <Link to="/generell-info#minside">Min Side</Link>.
        </Typography>

        <Typography id="adgangskort" variant="h4" component="h3">
          Aktivere adgangskort
        </Typography>
        <Typography variant="body1" paragraph>
          <ol>
            <li>Benytt kode mottatt på SMS for å åpne inngangsdøren.</li>
            <li>Ta et adgangskort fra resepsjonsdisken.</li>
            <li>Finn nummeret på kortets bakside.</li>
            <li>
              Logg inn på <Link to="/generell-info#minside">Min Side</Link> og
              gå til "Adgang". Trykk på knappen "Aktiver Kort", tast inn
              nummeret på kortet og trykk "Aktiver".
            </li>
            <li>Test kortet ved å låse opp inngangsdøren.</li>
          </ol>
        </Typography>

        <Typography id="provetime" variant="h4" component="h3">
          Prøvetime
        </Typography>

        <Typography variant="body1" paragraph>
          Prøvetime er gratis og kan gjennomføres på følgende måter:
          <ul>
            <li>
              Møt opp og registrer deg i{" "}
              <a href="#resepsjonstider">resepsjonstiden</a>.
            </li>
            <li>
              Utenfor resepsjonstid:{" "}
              <a href="/bestill-provetime">bestilles</a> og godkjennes på
              forhånd.
            </li>
          </ul>
          <b>Obs!</b> Uanmeldte/ikke forhåndsgodkjente prøvetimer utenfor
          resepsjonstid gebyrlegges à kr 500,-.
        </Typography>

        <Typography id="dropin" variant="h4" component="h3">
          Drop-in
        </Typography>
        <Typography variant="body1" paragraph>
          For deg som ikke har behov for permanent medlemskap, men som ønsker å
          trene hos oss innimellom, tilbyr vi gunstige priser på drop-in timer,
          enkeltuker og enkeltmåneder med trening.{" "}
          <a href="/bestill-provetime">Bestill drop-in</a> gjennom vårt skjema.
          Enkeltuker/-måneder bestilles gjennom vår{" "}
          <a href={signupUrl}>online innmelding</a>. Hvis du har adgangskort fra
          før kan du ta <a href="#kontakt">kontakt</a> med oss for ny
          bestilling.
        </Typography>

        <Typography id="minside" variant="h3" component="h2">
          Min Side
        </Typography>
        <Typography variant="body1" paragraph>
          Du finner <a href={process.env.REACT_APP_MYPAGE_URL}>Min Side</a> i
          hamburger-menyen (3 streker) på mobil eller i toppmenyen på
          nettbrett/PC. Første gang du logger inn trykker du på "Opprett passord
          / Glemt passord", og følger retningslinjene. Når passordet er satt kan
          du logge inn. <br />
          <br />
          På Min Side kan du: <br />
          <ul>
            <li>Aktivere og bestille nytt adgangskort.</li>
            <li>Se, legge til og endre dine fryseperioder.</li>
            <li>Se og si opp medlemskapet ditt.</li>
            <li>Se og redigere din personalia.</li>
          </ul>
          Verge/forelder av medlem har mulighet til å logge inn på vegne av
          medlemmet, og se og gjøre de samme handlingene nevnt ovenfor.
        </Typography>

        <Typography id="frysing" variant="h3" component="h2">
          Frysing
        </Typography>
        <Typography variant="body1" paragraph>
          Løpende medlemskap kan fryses gratis inntil 3 måneder per 12 måneder.
          Frysing kan starte og slutte når som helst i måneden.
          <br />
          Du fryser medlemskapet ditt på{" "}
          <Link to="/generell-info#minside">Min Side</Link>.
        </Typography>

        <Typography id="oppsigelse" variant="h3" component="h2">
          Oppsigelse
        </Typography>
        <Typography variant="body1" paragraph>
          Du avslutter medlemskapet ditt på{" "}
          <Link to="/generell-info#minside">Min Side</Link>.
        </Typography>
        <Typography variant="body1" paragraph>
          <b>Obs!</b> Medlemskap kan ikke avsluttes i en fryseperiode. For å
          avslutte medlemskapet må fryseperioden først avsluttes.
        </Typography>
      </div>
    </>
  );
};

export default GeneralInfo;
